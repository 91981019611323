/**
 * アコーディオンの動作設定
 */
export function setAccordion() {
  const $ac = $('.js-ac');

  $ac.on('click', event => {
    event.preventDefault();
    const $this = $(event.currentTarget);
    const targetId = $this.attr('data-ac-target');
    const $acTarget = $('#' + targetId);
    $this.toggleClass('is-active');
    $acTarget.slideToggle(200);
  });
}
