import { isSp } from './util';

/**
 * イメージギャラリーの設定を行う
 */
export function setImageModal() {
  $('.js-imgModal').each(function() {
    const $galleryItem = $(this).find('.js-imgModal-item');
    $galleryItem.magnificPopup({
      type: 'image',
      fixedContentPos: true,
      gallery: {
        enabled: true,
      },
      mainClass: 'mfp-fade',
      removalDelay: 300,
    });
  });
}

/**
 * イメージギャラリーのボックスの高さを調整する
 */
export function setImageBoxAccordion() {
  $('.js-imageBox_wrap').each(function() {
    const $this = $(this);
    const $imageBox = $this.find('.js-imageBox');
    const $images = $imageBox.find('.js-imageBox-item');
    const $acButton = $this.find('.js-imageBoxAcBtn');
    const $acButtonWrap = $this.find('.js-imageBoxAcBtn_wrap');

    // 写真が3枚以上ある時
    if ($images.length > 3) {
      const imageHeight = $images
        .eq(0)
        // .find('img')
        .height();

      const initialBoxHeight = isSp()
        ? imageHeight * 3 + $imageBox.width() * 0.05 * 2
        : imageHeight;

      $imageBox.css({ height: initialBoxHeight + 'px', overflow: 'hidden' });

      $acButton.on('click', function(event) {
        event.preventDefault();
        if ($imageBox.hasClass('is-open')) {
          $acButton.find('span').text('もっと見る');
          $acButton.removeClass('selected');
          $imageBox
            .css({ height: initialBoxHeight + 'px' })
            .removeClass('is-open');
        } else {
          $acButton.find('span').text('閉じる');
          $acButton.addClass('selected');
          $imageBox.css({ height: 'auto' }).addClass('is-open');
        }
      });
    } else {
      $acButtonWrap.hide();
    }
  });
}
