/**
 * 検索窓の開閉設定を行う
 */
export function setSearchOpen() {
  // ヘッダーの検索展開
  const $searchOpen = $('.js-searchOpen');
  const $searchClose = $('.js-searchClose');
  const $searchInput = $('.js-searchInput');
  const $searchForm = $('.js-searchForm');
  let searchState = false;

  $searchOpen.on('click', function() {
    if(searchState) {
      $searchForm.submit();
    }else{
      $searchInput.addClass('is-open');
      searchState = true;
      setTimeout(function() {
        $searchInput.find('input').focus();
      }, 300);
    }
  });

  $searchClose.on('click', function() {
    $searchInput.removeClass('is-open');
    searchState = false;
  });
}

/**
 * メガメニューの設定を行う
 */
export function setMegaMenu() {
  // メガメニューの展開
  const $headerMenu = $('.js-headerMenu');
  const $headerMegaCont = $('.js-headerMegaCont');
  let megaState = false;

  $headerMenu.on('click', function() {
    const $dataMenu = $(this).data('menu');

    $headerMenu.removeClass('is-active');
    $(this).addClass('is-active');

    $headerMegaCont.removeClass('is-open');
    $headerMegaCont.each(function() {
      if ($dataMenu === $(this).data('mega')) {
        $(this).addClass('is-open');
        megaState = true;
      }
    });
  });

  // メガメニューを閉じる
  const $megaClose = $('.js-megaClose');

  $megaClose.on('click', function() {
    $headerMenu.removeClass('is-active');
    $headerMegaCont.removeClass('is-open');
    megaState = false;
  });

  //ヘッダー（メガメニュー）以外をクリックした時、メガメニューを閉じる
  $(document).on('click', function(e){
    if(!$(e.target).closest('header').length && megaState){
      $headerMenu.removeClass('is-active');
      $headerMegaCont.removeClass('is-open');
      megaState = false;
    }
  });
}

/**
 * ハンバーガーメニューの設定を行う
 */
export function setHamburgerMenu() {
  // ハンバーガーメニュー展開
  const $hamburger = $('.js-hamburger');
  const $menuSp = $('.js-menuSp');

  $hamburger.on('click', function() {
    $(this).toggleClass('is-open');

    if ($(this).hasClass('is-open')) {
      $(this).removeClass('is-close');
    } else {
      $(this).addClass('is-close');
    }

    $menuSp.toggleClass('is-view');
  });
}