import { isSp } from './util';

/**
 * タップした時に拡大した図を表示する
 */
export function setTapZoom() {
  $('.js-tapZoom').click(function() {
    if (isSp()) {
      $(this)
        .find('img')
        .toggleClass('active');
      $(this).toggleClass('filterNone');
    }
  });
}
