import throttle from 'lodash/throttle';
import 'magnific-popup';
import { setHamburgerMenu, setMegaMenu, setSearchOpen } from './modules/header';
import { setAccordion } from './modules/ac';
import { setSlider, sliderSetting } from './modules/slide';
import { imgSwitch } from './modules/imgSwitch';
import { setImageBoxAccordion, setImageModal } from './modules/imageGallery';
import { setTapZoom } from './modules/tapZoom';

$(() => {
  setViewPort();
  setSmoothScroll();
  setAccordion();
  setSearchOpen();
  setMegaMenu();
  setHamburgerMenu();
  setImageModal();
  setTapZoom();
});

/**
 * ua処理
 */
var ua = navigator.userAgent;
if (
  ua.indexOf('iPhone') > 0 ||
  (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)
) {
  // スマートフォン用コード
} else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
  // タブレット用コード
  $('a').addClass('is-tablet');
} else {
  // PC用コード
}

var windowHeight;
var footerOffset;

/**
 * リサイズ・ロード時
 */
$(window).on(
  'resize load',
  throttle(() => {
    windowHeight = $(window).height();
    footerOffset = $('footer').offset();
  }, 10)
);

/**
 * スクロール・ロード時
 */
$(window).on(
  'scroll load',
  throttle(() => {
    const footerOffset = $('footer').offset();
    const scroll = $(window).scrollTop();
    if (scroll > 300) {
      $('.js-btnTop').addClass('is-view');
    } else {
      $('.js-btnTop').removeClass('is-view');
    }

    if (scroll + windowHeight > footerOffset.top) {
      $('.js-btnTop').addClass('is-sticky');
    } else {
      $('.js-btnTop').removeClass('is-sticky');
    }

    if (scroll > 0) {
      $('header').addClass('is-scroll');
    } else {
      $('header').removeClass('is-scroll');
    }
  }, 10)
);

/**
 * ロード時
 */
$(window).on('load', function () {
  const $aside = $('.js-aside');
  setSlider();
  setImageBoxAccordion();

  // spのサイドナビ分の高さページ最下部に隙間を空ける
  if ($(window).width() < 769) {
    $('body').css('paddingBottom', $aside.outerHeight());
  }
});

/**
 * ウィンドウサイズの変更・ロード時
 */
$(window).on(
  'resize load',
  throttle(() => {
    imgSwitch();
    sliderSetting();
  }, 10)
);

/**
 * ビューポートの設定を行う
 */
function setViewPort() {
  const ua = navigator.userAgent;
  if (
    ua.indexOf('iPhone') > 0 ||
    (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)
  ) {
    // スマートフォン用コード
  } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
    // タブレット用コード
    $('head').append('<meta name="viewport" content="width=1240">');
    $('.l-header_link').addClass('is-scroll');
  } else {
    // PC用コード
  }
}

/**
 * スムーズスクロールの設定
 */
function setSmoothScroll() {
  $('a[href^="#"]')
    .not('a.notScroll')
    .click(function () {
      const speed = 500;
      const href = $(this).attr('href');
      const $target = $(href === '#' || href === '' ? 'html' : href);
      const $header = $('header');
      const position = $target.offset().top;
      const headerHeight = $header.outerHeight() ? $header.outerHeight() : 0;
      $('html, body').animate(
        { scrollTop: position - headerHeight },
        speed,
        'swing'
      );
      return false;
    });
}
