import 'slick-carousel';

/**
 * スライダーの設定を行う
 */
export function setSlider() {
  const $bnrSlide = $('.js-bnrSlide');
  const $bnrSingleSlide = $('.js-bnrSingleSlide');
  const $bnrSpOnly = $('.js-bnrSpOnly');

  // if ($bnrSlide.length) {
  //   $bnrSlide.slick({
  //     autoplay: true,
  //     infinite: true,
  //     speed: 500,
  //     arrows: false,
  //     slidesToShow: 5,
  //     responsive: [{
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //         centerMode: true,
  //         centerPadding: '14%',
  //         swipeToSlide: true,
  //         focusOnSelect: true,
  //       },
  //     },],
  //     dots: false,
  //   });

  //   $bnrSlide.addClass('is-view');
  // }

  if ($bnrSingleSlide.length) {
    $bnrSingleSlide.slick({
      arrows: true,
      infinite: true,
      speed: 600,
      dots: false,
      swipeToSlide: true,
      focusOnSelect: true,
    });

    $bnrSingleSlide.addClass('is-view');
  }

  if ($bnrSpOnly.length) {
    $bnrSpOnly.slick({
      arrows: true,
      infinite: true,
      speed: 600,
      dots: false,
      swipeToSlide: true,
      focusOnSelect: true,
    });

    $bnrSpOnly.addClass('is-view');
  }
}

// PCではスライドせず、SPではスライドさせる(TOPのbnr用)
export function sliderSetting() {

  if ($(window).width() < 769) {
    $('.js-bnrSlide').not('.slick-initialized').slick({
      autoplay: false,
      arrows: false,
      slidesToShow: 1,
      centerMode: true,
      centerPadding: '14%',
      swipeToSlide: true,
      focusOnSelect: true,
    });
  } else {
    $('.js-bnrSlide.slick-initialized').slick('unslick');
  }

  $('.js-bnrSlide').addClass('is-view');
}
