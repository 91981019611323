import { isSp } from './util';

/**
 * ウィンドウサイズによって_pcと_spの画像を出し分ける
 */
export function imgSwitch() {
  const $imgSwitch = $('.js-imgSwitch');
  const sp = '_sp.';
  const pc = '_pc.';

  $imgSwitch.each(function() {
    const $this = $(this);
    if (!isSp()) {
      $this.attr('src', $this.attr('src').replace(sp, pc));
    } else {
      $this.attr('src', $this.attr('src').replace(pc, sp));
    }
  });
}
